import React from "react";
import {graphql} from "gatsby";

import Layout from "../../../components/layout";
import HelpMethodsSection from "../../../components/helpMethodsSection";
import ImageGallery from "react-image-gallery";

function SuccessStoriesPost(props) {
  const {data} = props;
  const {title, storyGallery} = data.markdownRemark.frontmatter;
  const __html = data.markdownRemark.html;

  const galleryImages = (storyGallery || []).map(({image, caption}) => ({
    original: image,
    thumbnail: image,
    description: caption,
  }));

  return (
    <Layout activeLink="/success-stories" pageTitle={title} {...props}>
      <div className="md:flex flex-row mt-10 xl:mt-20">
        <div className="px-10 md:pl-20 mb-10">
          <p className="text-4xl mb-5">{title}</p>
          <div className="text-justify markdown-html mb-10" dangerouslySetInnerHTML={{__html}}/>
          {!!storyGallery?.length && (
            <div>
              <ImageGallery items={galleryImages} showThumbnails={false}/>
            </div>
          )}
        </div>
      </div>
      <HelpMethodsSection/>
    </Layout>
  )
}

export default SuccessStoriesPost;

export const successStoriesPostQuery = graphql`
  query SuccessStoriesPostQuery($slug: String) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        title
        coverPicture
        storyGallery {
          caption
          image
        }
      }
      html
    }
  }
`;
